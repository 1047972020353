import React from "react";
import FamousCourse from "../../sections/famous-course/FamousCourse";

function Courses(props) {
  return (
    <div>
      <FamousCourse />
    </div>
  );
}

export default Courses;
